<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerP="P1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<div class="Crumbs">
			<div>当前位置：首页>积分商城><span>金融</span></div>
		</div>
		<!-- 电商板块 -->
		<div class="Electricity">
			<div class="Electricity_cont">
				<!-- <h1>电商板块</h1> -->
				<div class="Electricity_cont_item">
					<div class="Electricity_cont_item1" id="sector">
						<div class="Electricity_cont_item1_text">
							金融行业数字化转型
						</div>
						<div class="Electricity_cont_item2_text">
							将金融服务覆盖用户生活等高频场景，多方位触达客户需求
						</div>
						<div >
							<img class="img"
								:src="$imgUrl+'/yuyua/img/bootmall/jinrong_1.png'"
								alt=""
							/>
						</div>
					</div>
					<div class="Electricity_cont_item1">
						<div class="Electricity_cont_item1_text">
							借力高频场景，巧妙切入营销
						</div>
						<div class="Electricity_cont_item2_text">
							营销策略与高频场景联动，挖掘生活消费、热门节日等场景元素，通过用户熟悉的场景触达，提升金融产品的受欢迎程度，同时促进用户活跃程度，提升留存转化
						</div>
						<div>
							<img class="img" style="width: 500px;height: 430px;"
								:src="$imgUrl+'/yuyua/img/bootmall/jinrong_2_2.png'"
								alt=""
							/>
						</div>
					</div>
					<div class="Electricity_cont_item1">
						<div class="Electricity_cont_item1_text">
							积分兑换丰富多样，趣味挑战引力十足
						</div>
						<!-- <div class="Electricity_cont_item2_text">
							根据业务场景制定营销节点专属方案，轻松带来用户增长
						</div> -->
						<div>
							<img class="img"
								:src="$imgUrl+'/yuyua/img/bootmall/jinrong_3.png'"
								alt=""
							/>
						</div>
					</div>
					<div class="Electricity_cont_item1">
						<div class="Electricity_cont_item1_text">
							精准营销高效引流，轻松做好用户增长
						</div>
						<div class="Electricity_cont_item2_text">
							将节日热点及传统文化相结合，演绎品牌营销新可能
						</div>
						<div class="box">
							<ul class="list">
								<li><img class="pic" :src="$imgUrl+'/yuyua/img/bootmall/chunjie_0.png'" alt=""/></li>
								<!-- <li><img class="pic" :src="$imgUrl+'/yuyua/img/bootmall/nvshengjie_1.png'" alt=""/></li> -->
								<li><img class="pic" :src="$imgUrl+'/yuyua/img/bootmall/qingmingjie_2.png'" alt=""/></li>
								<li><img class="pic" :src="$imgUrl+'/yuyua/img/bootmall/laodongjie_3.png'" alt=""/></li>
								<li><img class="pic" :src="$imgUrl+'/yuyua/img/bootmall/muqingjie_4.png'" alt=""/></li>
								<li><img class="pic" :src="$imgUrl+'/yuyua/img/bootmall/duanwujie_5.png'" alt=""/></li>
								<li><img class="pic" :src="$imgUrl+'/yuyua/img/bootmall/zhongqiujie_6.png'" alt=""/></li>
								<li><img class="pic" :src="$imgUrl+'/yuyua/img/bootmall/guoqingjie_7.png'" alt=""/></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>

		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import banner2 from "../../components/banner.vue";
import nav2 from "../../components/nav.vue";
export default {
	name: "keji",
	components: {
		footer2,
		nav2,
		banner2,
	},
	data() {
		return {
			h1: "金融行业数字化解决方案",
			P1: "搭建积分运营体系持续维持会员活跃，多维度建立数字化营销，快速获客，强化品牌IP认同，挖掘用户存量价值",
			Img1: this.$imgUrl+"/yuyua/img/bootmall/jinrong.png",
		};
	},
	methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs span {
	color: #000000;
}
/* 电商板块 */
.Electricity_cont {
	width: 1200px;
	margin: 0 auto;
	height: 2800px;
	text-align: center;
}

.Electricity_cont_item1 {
	width: 100%;
	min-width: 1200px;
	height: 700px;
	min-height: 700px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	border-top: 1px solid hsla(0,0%,91%,.5);
}

.Electricity_cont_item1_text {
	margin: 53px 0 20px;
	width: 100%;
	height: 48px;
	font-size: 34px;
	font-weight: 500;
	color: #2d2d2d;
}

.Electricity_cont_item2_text {
	padding: 0 60px;
	margin-bottom: 60px;
	width: 1159px;
	min-height: 28px;
	font-size: 20px;
	font-weight: 400;
	color: #2d2d2d;
	line-height: 28px;
}

.pic {
	width: 200px;
	height: 430px;
}
.box {
	margin-top: 100px;
	margin-left: 150px;
	position: absolute;
	/* 让整个盒子居中 */
	transform: translate(-10%, 20%);
	width: 1000px;
	height: 450px;
	/* 溢出隐藏 */
	overflow: hidden;
}
 
.list {
	margin-left: 300px;
	margin-right: 400px;
    position: absolute;
	/*  top: 0;
		left: 0; */
	/* 设置ul更大的宽度,让子元素li可以并排浮动 */
	width: 4000px;
	height: 450px;
}
 
 .list li {
	/* 让每一个li标签并排浮动 */
	margin-left: 30px;
	float: left;
	width: 300px;
	height: 450px;
	/* 使用animation标签，设置动画名称 时间 循环次数（此处为无限循环） 匀速 */
	animation: move 10s infinite linear;
}
 
        /* 定义动画 */
@keyframes move {
 
             /* 第1张 */
            0% {
                transform: translateX(0px);
            }
			
			/* 第2张 */
            10% {
                transform: translateX(-300px);
            }
            /* 设置同样的值让图片停留一会 */
            20% {
                transform: translateX(-300px);
            }
			
			/* 第3张 */
			25% {
				transform: translateX(-600px);
			}
            35% {
                transform: translateX(-600px);
            }
 
			/* 第4张 */
            40% {
                transform: translateX(-900px);
            }
            50% {
                transform: translateX(-900px);
            }
 
			/* 第5张 */
            55% {
                transform: translateX(-1200px);
            }
			65% {
				transform: translateX(-1200px);
			}
			
			/* 第6张 */
			70% {
				transform: translateX(-1500px);
			}
			80% {
				transform: translateX(-1500px);
			}
			
			/* 第7张 */
			90% {
				transform: translateX(-1800px);
			}
			100% {
				transform: translateX(-1800px);
			}

        }
 
</style>
