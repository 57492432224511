<template>
	<div>
		<div class="wrap">
			<div class="logo">
				<img :src="logoimg" />
			</div>
			<div class="nav">
				<div class="dropdown">
					<button class="dropbtn" @click="funRouter">积分商城</button>
					<div class="dropdown-content">
						<div>
							<div class="content-span">产品</div>
							<div>解决方案</div>
						</div>
						<div>
							<span><hr style="width:260px;border:1px groove;" /></span>
						</div>
						<div>
							<div class="content-span"><router-link to="/jifenyunying">积分运营</router-link></div>
							<div><router-link to="/lingshou">零售</router-link></div>
						</div>
						<div>
							<div class="content-span"><router-link to="/quanyizhongxin">权益中心</router-link></div>
							<div><router-link to="/hulianwang">互联网</router-link></div>
						</div>
						<div>
							<div class="content-span"><router-link to="/hudongyingxiao">互动营销</router-link></div>
							<div><router-link to="/jinrong">金融</router-link></div>
						</div>
					</div>
				</div>
				<ul>
					<li
						v-for="(item, index) in navList"
						:key="index"
						@click="funyuming(item.url)"
					>
						{{ item.title }}
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Nav",
	data() {
		return {
			navList: [
				{ title: "首页", url: "/" },
				{ title: "公司概况", url: "/jituangaikuo" },
				// { title: "公司产业", url: "/keji" },
				// { title: "公司新闻", url: "/xinwen" },
				{ title: "积分商城", url: "/bootmall" },
				{ title: "云仓平台", url: "/bootflow" },
				// { title: "招聘详情", url: "/zhaopinxiangqing0_0" },
				{ title: "联系我们", url: "/lianxi" },
				// { title: "招聘信息", url: "/zhaopinxinxi0" },
			],
			logoimg: this.$imgUrl+"/yuyua/img/logo.png",
		};
	},
	methods: {
		funyuming(msg) {
			this.$router.push(msg);
		},
		funRouter() {
			this.$router.push("/bootmall");
		},
	},
};
</script>
<style scoped>
.wrap {
	position: relative;
	width: 100vw;
	height: 101px;
	background-color: rgba(255, 255, 255, 1);
	box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
}

.wrap > .logo > img {
	position: absolute;
	left: 360px;
	top: 23px;
	width: 181px;
	height: 54px;
}

.nav {
	width: 800px;
	position: absolute;
	left: 800px;
	top: -10px;
}

.nav ul {
	height: 100%;
	margin-top: -10px;
}

.nav ul li {
	height: 46px;
	float: left;
	display: flex;
	list-style-type: none;
	color: rgba(68, 68, 68, 1);
	font-size: 25px;
	margin-right: 50px;
	line-height: 30%;
	cursor: pointer;
}

.nav ul li:hover {
	color: rgba(35, 51, 127, 1);
	border-bottom: 2px solid rgba(35, 51, 127, 1);
}
.dropbtn {
	background-color: #fff;
	color: rgba(68, 68, 68, 1);
	padding: 16px;
	font-size: 25px;
	border: none;
	cursor: pointer;
}

.dropdown {
	top: 28px;
	left: 280px;
	position: relative;
	display: inline-block;
	font-size: 25px;
}

.dropdown-content {
	z-index: 999;
	font-size: 14px;
	text-align: center;
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 300px;
	min-height: 120px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.dropdown-content a:hover {
	background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
	display: block;
}

.content-span {
	margin-left: 20px;
	float: left;
}

</style>
