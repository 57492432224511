<template>
	<!-- star -->
	<div class="kejipage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerP="P1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<div class="Crumbs">
			<div>当前位置：首页><span>云仓平台</span></div>
		</div>
		<!-- 科技板块 -->
		<div class="TechnologySector">
			<div class="TechnologySector_cont" id="sector">
				<h1>平台简介</h1>
				<div class="TechnologySector_cont_bottom">
					<img :src="$imgUrl+'/yuyua/img/keji/20230705165318.png'" alt="" />
					<div class="TechnologySector_cont_bottom_text">
						<div class="TechnologySector_cont_bottom_text_div">
							<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;以科技为引擎，以创新为动力，提供三网合一的产品充值能力，并可自由扩展下游资源。</span>
						</div>
						<p>
							&nbsp;&nbsp;&nbsp;&nbsp;云仓平台是一个话费，流量、会员充值的业务系统，提供三网合一的流量产品充值能力，并可自由扩展下游资源，
							如各地运营商后付费流量平台或第三方流量系统资源。云仓平台本身是一个具有充值能力的服务中心，用户可以在此基础上发展下游业务，
							比如互联网企业的各业务的用户奖励计划、话费充值、流量定期订购、APP会员充值、京东购物卡充值等业务。
							契合国家数字化经济发展方向，积极探索，不断拓展，为客户提供全面的解决方案，致力于打造综合性智慧化服务平台，为客户提供高效、便捷、个性化的智慧环境。
							<br />
							&nbsp;&nbsp;&nbsp;&nbsp;紧跟国家信息化发展趋势，依托云计算、大数据、人工智能、移动互联、物联网等新型技术，结合不同的应用场景需求，提供个性化的解决方案，
							致力于将最新的产品和最先进的技术应用到各行各业的数字化转型中。携手行业合作伙伴，共同服务于各行各业的数字化发展，助力智慧建设。
						</p>
					</div>
					<div class="TechnologySector_cont_bottom_text_english">
						YUYUAN_BOOTFLOW
					</div>
				</div>
			</div>
		</div>
		<!-- 教育 -->
		<div class="Education">
			<div class="Education_cont" id="education">
				<h1>数据安全</h1>
				<div class="Education_item">
					<div class="Education_item1">
						<img :src="$imgUrl+'/yuyua/img/keji/摄图网_500574440_banner_云计算（非企业商用）.png'" alt="" />
						<div class="Education_item_text">
							<p>云储存</p>
							<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过云计算提供商将数据和文件存储在互联网上，
							而您可以通过公共互联网或专用的专用网络连接访问这些数据和文件。提供商安全地存储、管理并维护存储服务器、基础设施和网络，
							以确保您在需要时能够以几乎无限的规模和弹性容量访问数据。借助云存储，无需购买和管理自己的数据存储基础设施，
							这样就可提供敏捷性、可扩展性和耐用性，并可随时随地访问数据。</span>
						</div>
					</div>
					<div class="Education_item1">
						<div class="Education_item_text">
							<p>安全备份</p>
							<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为了保障生产、销售、开发的正常运行，采取先进、有效的措施，
							将计算机系统中的所有文件和数据进行复制并保存至外部设备中，以防止系统故障、安防感染、误操作等导致数据丢失。</span>
						</div>
						<img :src="$imgUrl+'/yuyua/img/keji/20230706095043.png'" alt="" />
					</div>
					<div class="Education_item1">
						<img :src="$imgUrl+'/yuyua/img/keji/20230706103111.png'" alt="" />
						<div class="Education_item_text">
							<p>云上推广</p>
							<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过“上云”进行基础设施和信息系统的云化、生产经营业务流程优化等应用，
							实现连接社会化资源、共享能力及服务。运用智能化改造成熟度评估模型，科学评定企业智能化总体水平及技术成熟等级，
							帮助企业识别智能化水平差距，辅助企业制定与自身实际相符的智能化改造路径及企业上云阶段化目标，助力企业上云。</span
							>
						</div>
					</div>
					<div class="Education_item1">
						<div class="Education_item_text">
							<p>下游系统对接流程</p>
							<div class="Education_item1_block">
								<div>获得授权账户</div>
								<div>API 接入</div>
								<div class="div3">发送充值请求</div>
								<div class="div4">充值结果反馈</div>
								<div class="div5">交易完成</div>
							</div>
						</div>
						<img :src="$imgUrl+'/yuyua/img/keji/20230706112123.png'" alt="" />
					</div>
				</div>
			</div>
		</div>
		<!-- 城市 -->
		<div class="Citi">
			<div class="Citi_cont" id="citi">
				<h1>业务运营</h1>
				<p class="Citi_cont_p">
					科学完善的全链条业务服务体系助你扬帆远航，提高城镇化质量，实现精细化和动态管理，并提升城市管理成效和改善市民生活质量。
				</p>
				<div class="Citi_cont_item">
					<div
						class="Citi_cont_item1"
						v-for="(item, index) in arrList"
						:key="index"
					>
						<img :src="item.url" alt="" />
						<p>{{ item.p }}</p>
						<span>{{ item.span }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- 华为云 -->
		<div class="HuaWei">
			<div class="HuaWei_cont" id="aliyun">
				<h1>阿里云</h1>
				<p class="HuaWei_cont_p">
					携手阿里专业、安全、可信的云计算产品，全球领先的研发创新能力，满足更广泛业务需求的行业解决方案，建设和谐共赢的云生态系统。
				</p>
				<div class="HuaWei_cont_item">
					<div class="HuaWei_cont_item_l">
						<div class="HuaWei_cont_item_l1">
							<p>云服务</p>
							<span>弹性云服务器 云容器引 <br />
								弹性伸缩服务 镜像服务 专属云</span>
						</div>
						<div class="HuaWei_cont_item_l2">
							<p>云存储</p>
							<span>云硬盘 对象储存服务 云硬盘备份 <br />
								弹性伸缩服务 镜像服务 专属云</span>
						</div>
					</div>
					<div class="HuaWei_cont_item_img">
						<img :src="$imgUrl+'/yuyua/img/keji/编组 11.png'" alt="" />
					</div>
					<div class="HuaWei_cont_item_r">
						<div class="HuaWei_cont_item_r1">
							<p>云安全</p>
							<span>Anti-DDos流量清洗 漏洞扫描服务 安全指数服务 <br />
								企业主机安全 WEB应用防火墙 数据库安全服务</span>
						</div>
						<div class="HuaWei_cont_item_r2">
							<p>云数据库</p>
							<span>云数据库MongoDB Redis分布式缓存服务 文档数据库服务</span>
						</div>
					</div>
				</div>
				<div class="HuaWei_cont_item_c">
					<p>云网络</p>
					<span>虚拟私有云 弹性负载均衡 虚拟专用网络</span>
				</div>
			</div>
		</div>
		<!-- footer -->
		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
export default {
	name: "keji",
	components: {
		footer2,
		nav2,
		banner2,
	},
	data() {
		return {
			h1: "云仓平台",
			P1: "帮助品牌方打通销售通路，提高数字商品流通效率；为消费端构建权益场景，提升用户活跃！",
			Img1: this.$imgUrl+"/yuyua/img/keji/20230705162508.jpg",
			arrList: [
				{
					url: this.$imgUrl+"/yuyua/img/keji/人脸识别.png",
					p: "电商选品工具",
					span: "精准覆盖热门行业:可以实时掌握最新品类分布和价格动态，让市场和利润一目了然。顶尖的大数据选品工具:智能可视化分析，同时对比全球商品分布及价格趋势，选品趋势不再手忙脚乱。 专业分析师视角，多年行业经验沉淀在系统中，辅助你高瞻远瞩。",
				},
				{
					url: this.$imgUrl+"/yuyua/img/keji/考试.png",
					p: "增值服务",
					span: "简单快速为你解决营销困境,包含本地化素材制作、广告代投，多平台政策解读、定制化投放策略",
				},
				{
					url: this.$imgUrl+"/yuyua/img/keji/日历.png",
					p: "营销服务",
					span: "托渠道优势开发的网红推荐等创新的营销形式，做到充分覆盖营销各阶段；深度再营销可以更精准的定位和转化高价值买家，加强客户合作意向。",
				},
			],
		};
	},
	methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.kejipage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs span {
	color: #000000;
}
/* 科技板块 */
.TechnologySector_cont {
	width: 1200px;
	height: 810px;
	margin: 0 auto;
	padding-top: 64px;
}
.TechnologySector_cont h1 {
	font-size: 32px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 45px;
	text-align: center;
}
.TechnologySector_cont_bottom img {
	margin-top: 45px;
	float: right;
	width: 1000px;
	height: 520px;
}
.TechnologySector_cont_bottom_text {
	margin-top: -424px;
	float: left;
	width: 720px;
	height: 504px;
	background: #f8f8f8;
	z-index: 10;
}
.TechnologySector_cont_bottom_text div {
	margin-top: 36px;

	font-size: 16px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #333333;
	line-height: 32px;
}
.TechnologySector_cont_bottom_text p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 35px;
	margin-top: 24px;
}
.TechnologySector_cont_bottom_text_english {
	font-size: 70px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #f8f8f8;
	line-height: 98px;
}
/* 教育 */
.Education {
	width: 100vw;
	height: 1741px;
	background: #f8f8f8;
}
.Education_cont {
	width: 1200px;
	margin: 0 auto;
	padding-top: 91px;
}
.Education_cont h1 {
	text-align: center;
	font-size: 32px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 45px;
}
.Education_item {
	margin-top: 45px;
}
.Education_item1 {
	display: flex;
}
.Education_item_text {
	background: #ffffff;
	width: 740px;
	height: 340px;
}
.Education_item_text p {
	margin-top: 72px;
	margin-left: 86px;
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: red;
	line-height: 33px;
}
.Education_item_text span {
	width: 568px;
	height: 140px;
	margin-top: 24px;
	margin-left: 86px;

	display: inline-block;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 28px;
}
.Education_item1 {
	margin-bottom: 40px;
}
.Education_item1_block {
	width: 550px;
	display: flex;
	flex-wrap: wrap;
	margin-left: 86px;
}

.Education_item1_block .div3 {
	margin: 40px 24px 38px 0;
	line-height: 40px;
	text-align: center;
	width: 172px;
	height: 40px;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #d7d7d7;
}
.Education_item1_block .div4 {
	margin-top: -10px;
}
.Education_item1_block .div5 {
	margin-top: -10px;
}

.Education_item1_block div {
	margin: 40px 24px 38px 0;
	line-height: 40px;

	text-align: center;
	width: 140px;
	height: 40px;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #d7d7d7;
}
/* 城市 */
.Citi {
	width: 100vw;
	height: 883px;
	background-image: this.$imgUrl+"/yuyua/img/keji/蒙版.png";
}
.Citi_cont {
	width: 1200px;
	margin: 0 auto;
}
.Citi_cont > h1 {
	text-align: center;
	margin-top: 83px;

	font-size: 32px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 45px;
}
.Citi_cont_p {
	text-align: center;
	margin-top: 40px;

	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 32px;
}
.Citi_cont_item {
	display: flex;
	justify-content: space-between;
	width: 1200px;
	margin-top: 64px;
}
.Citi_cont_item1 {
	width: 304px;
}

.Citi_cont_item1 p {
	margin: 24px 0;
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #23337f;
	line-height: 25px;
}
.Citi_cont_item1 span {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 24px;
}
/* 华为 */
.HuaWei {
	width: 100vw;
	height: 809px;
	background: #ffffff;
}
.HuaWei_cont {
	padding-top: 80px;
	width: 1200px;
	margin: 0 auto;
}
.HuaWei_cont > h1 {
	text-align: center;
	font-size: 32px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 45px;
}
.HuaWei_cont_p {
	margin-top: 60px;
	text-align: center;
	font-size: 16px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 22px;
}

.HuaWei_cont_item {
	display: flex;
	margin-top: 81px;
	margin-left: 150px;
}

.HuaWei_cont_item_img img {
	text-align: center;
	width: 320px;
	height: 326px;
}
.HuaWei_cont_item_l1 {
	text-align: right;

	margin-right: 41px;
}
.HuaWei_cont_item_l1 p {
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 33px;
	margin-left: 180px;
}
.HuaWei_cont_item_l1 span {
	float: right;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 20px;
}
.HuaWei_cont_item_l2 {
	text-align: right;
	margin-right: 41px;

	margin-top: 90px;
}
.HuaWei_cont_item_l2 p {
	margin-left: 180px;
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 33px;
	margin-left: 180px;
}
.HuaWei_cont_item_l2 span {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 20px;
}
.HuaWei_cont_item_r {
	margin-left: 16px;
}
.HuaWei_cont_item_r1 p {
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 33px;
}
.HuaWei_cont_item_r1 span {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 20px;
}
.HuaWei_cont_item_r2 {
	margin-top: 90px;
}
.HuaWei_cont_item_r2 p {
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 33px;
}
.HuaWei_cont_item_r2 span {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 20px;
}
.HuaWei_cont_item_c {
	margin-top: 41px;
	text-align: center;
}
.HuaWei_cont_item_c p {
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 33px;
}
.HuaWei_cont_item_c span {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 20px;
}
</style>
