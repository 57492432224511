<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerP="P1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<div class="Crumbs">
			<div>当前位置：首页>积分商城><span>互动营销</span></div>
		</div>
		<!-- 电商板块 -->
		<div class="Electricity">
			<div class="Electricity_cont">
				<!-- <h1>电商板块</h1> -->
				<div class="Electricity_cont_item">
					<div class="Electricity_cont_item1" id="sector">
						<img :src="$imgUrl+'/yuyua/img/bootmall/hudongyingxiao_1.png'" alt=""/>
						<div class="Electricity_cont_item1_text">
							<h1>海量的权益商品</h1>
							<p>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;权益商品包含京东自营等渠道实物，全领域王牌电子卡券，大数据构建热门商品推荐池任您选
							</p>
						</div>
					</div>
					<div class="Electricity_cont_item1">
						<div class="Electricity_cont_item1_text">
							<h1>灵活的营销配置</h1>
							<p>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持您将京东自营等渠道商品设置为兑换商品，无需压货，支持一件代发、配送、售后等服务，降低仓储和运营成本
							</p>
						</div>
						<img :src="$imgUrl+'/yuyua/img/bootmall/hudongyingxiao_2.png'" alt=""/>
					</div>
					<div class="Electricity_cont_item1">
						<img :src="$imgUrl+'/yuyua/img/bootmall/hudongyingxiao_3.png'" alt=""/>
						<div class="Electricity_cont_item1_text">
							<h1>可视化数据看板</h1>
							<p>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;制定标准，统一的数据接口，满足跨平台、跨语言、跨系统的调用。一次对接，全程服务
							</p>
						</div>
					</div>
					<!-- <div class="Electricity_cont_item1" id="sector">
						<div class="Electricity_cont_item1_text">
							<h1>玩法多样，场景互通</h1>
							<p>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;个性化玩法提升留存，经营场景打通，建立积分生态；多种积分玩法，如积分秒杀/团购，可快速根据企业特点打造积分活动，并通过多种渠道触达用户；企业内经营场景打通，积分互通互认，提高积分价值和用户体验。提供积分生态联盟解决方案，为用户增长建立新方向
							</p>
						</div>
						<img
							src="../../assets/img/bootmall/jifenyunying_4.png"
							alt=""
						/>
					</div> -->
				</div>
			</div>
		</div>

		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import banner2 from "../../components/banner.vue";
import nav2 from "../../components/nav.vue";
export default {
	name: "keji",
	components: {
		footer2,
		nav2,
		banner2,
	},
	data() {
		return {
			h1: "多样化的营销玩法-打造低成本引流裂变闭环",
			P1: "游戏化、场景化的营销策略，增加用户获取积分的乐趣，以海量的权益作为奖励，促进用户裂变式互动，只能工具管理用户运营更高效",
			Img1: this.$imgUrl+"/yuyua/img/bootmall/hudongyingxiao.png",
		};
	},
	methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs span {
	color: #000000;
}
/* 电商板块 */
.Electricity_cont {
	width: 1200px;
	margin: 0 auto;
	height: 1100px;
}
.Electricity_cont > h1 {
	text-align: center;
	margin: 65px 0 45px 0;
}
.Electricity_cont_item1 {
	margin-top: 60px;
	display: flex;
	justify-content: space-between;
}
.Electricity_cont_item1 p {
	margin-top: 40px;
	width: 600px;
	height: 200px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 40px;
}
</style>
